import {
  useAuthenticationService,
  useUserMetadata,
  usePreferredLanguageV2
} from 'admin-portal-shared-services';

import { Grid } from '@hexa-ui/components';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Languages,
  patchUserMetadata,
  PatchUserMetadataTypes
} from '../../services/user/userService';
import SecurityDetails from './components/SecurityDetails/SecurityDetails';
import PersonalPreferences from './components/PersonalPreferences/PersonalPreferences';
import UserDetails from './components/UserDetails/UserDetails';
import { PageTitle, StyledContainer, StyledDivider, GridItem } from './styles';

const UserProfile = (): JSX.Element => {
  const { data, mutateUserMetadata } = useUserMetadata();
  const authService = useAuthenticationService();
  const { preferredLanguage, defaultLanguage } = usePreferredLanguageV2();
  const { family_name: lastName, given_name: firstName } = authService.getUserFullNameB2C();
  const userEmail = authService.getUserEmailB2C();
  const { locale } = useIntl();

  const memoizedUserPreferences = useMemo(() => {
    return {
      preferredLanguage: preferredLanguage as Languages,
      preferredTimeFormat: data?.profile?.preferredTimeFormat,
      timezone: data?.profile?.timezone
    };
  }, [preferredLanguage, data?.profile]);

  const isFederated = authService.getIsFederated();

  const memoizedUserData = useMemo(() => {
    return {
      firstName,
      lastName,
      email: userEmail,
      isFederated
    };
  }, [firstName, lastName, userEmail, isFederated]);

  const handleChangePassword = () => {
    window.location.assign(`/auth/password/change?email=${userEmail}&lang=${locale}`);
  };

  const handleEditUserDetails = () => {
    window.location.assign(`/auth/profile-update?lang=${locale}`);
  };

  const handlePreferencesChange = async (userPreference: PatchUserMetadataTypes): Promise<void> => {
    const isRequestSuccessful = await patchUserMetadata(
      userPreference,
      memoizedUserPreferences.preferredLanguage ?? defaultLanguage
    );

    if (data && data.profile && isRequestSuccessful) {
      mutateUserMetadata({
        ...data,
        profile: {
          ...data.profile,
          ...userPreference
        }
      });
    }
  };

  const availableLanguages = [
    {
      abbreviation: 'pt-BR',
      name: 'Português - Brasil'
    },
    {
      abbreviation: 'en-US',
      name: 'English - United States'
    },
    {
      abbreviation: 'es-419',
      name: 'Español - America Latina'
    },
    {
      abbreviation: 'ko-KR',
      name: '한국어 - 한국'
    },
    {
      abbreviation: 'id-ID',
      name: 'Bahasa - Indonesia'
    }
  ];

  return (
    <StyledContainer>
      <PageTitle>
        <FormattedMessage id="userProfile.title" />
      </PageTitle>
      <StyledDivider />
      <Grid.Container type="fluid" style={{ margin: '0 auto', rowGap: '1.5rem' }}>
        <GridItem
          lg={8}
          xs={12}
          css={{
            '@desktopLarge': {
              paddingRight: '$6'
            }
          }}
        >
          <UserDetails handleEditClick={handleEditUserDetails} userData={memoizedUserData} />
        </GridItem>
        <GridItem lg={4} xs={12}>
          <SecurityDetails
            handlePasswordEditClick={handleChangePassword}
            userData={memoizedUserData}
          />
        </GridItem>
        <GridItem xs={12}>
          <PersonalPreferences
            handlePreferencesChange={handlePreferencesChange}
            availableLanguagesList={availableLanguages}
            userPreferences={memoizedUserPreferences}
          />
        </GridItem>
      </Grid.Container>
    </StyledContainer>
  );
};

export default UserProfile;
