import {
  TypeToast,
  useToast as toastService,
  useLogService as logService
} from 'admin-portal-shared-services';
import Api from '../../Api/Api';
import SERVICES_URLS from '../../utils/requestUtils';

export type Languages = 'pt-BR' | 'en-US' | 'es-419' | 'ko-KR' | 'id-ID';

export type PatchUserMetadataTypes = {
  preferredLanguage?: Languages;
  timezone?: string;
  preferredTimeFormat?: string;
};

type Messages = {
  [key: string]: {
    [key: string]: string;
  };
};

const successMessages: Messages = {
  preferredLanguage: {
    'pt-BR': 'Idioma de preferência alterado com sucesso!',
    'en-US': 'Your preferred language has been successfully updated.',
    'es-419': '¡Idioma preferido cambiado con éxito!',
    'ko-KR': '기본 언어가 성공적으로 업데이트되었습니다.',
    'id-ID': 'Bahasa Pilihan berhasil diperbarui.'
  },
  preferredTimeFormat: {
    'pt-BR': 'Formato de hora alterado com sucesso!',
    'en-US': 'Time format has been successfully updated.',
    'es-419': '¡Formato del tiempo cambiado con éxito!',
    'ko-KR': '시간 형식 언어가 성공적으로 업데이트되었습니다.',
    'id-ID': 'Format waktu berhasil diperbarui.'
  },
  timezone: {
    'pt-BR': 'Fuso horário alterado com sucesso!',
    'en-US': 'Your timezone has been successfully changed.',
    'es-419': '¡Zona horaria cambiada con éxito!',
    'ko-KR': '시간대가 성공적으로 업데이트되었습니다.',
    'id-ID': 'Zona waktu Anda berhasil diperbarui.'
  }
};

const errorMessages: Messages = {
  preferredLanguage: {
    'pt-BR': 'Ocorreu um erro ao atualizar o idioma de preferência',
    'en-US': 'There was an error updating the preferred language',
    'es-419': 'Se produjo un error al cambiar el idioma preferido.',
    'ko-KR': '기본 언어를 업데이트하는 중에 오류가 발생했습니다.',
    'id-ID': 'Terjadi kesalahan saat memperbarui bahasa pilihan'
  },
  preferredTimeFormat: {
    'pt-BR': 'Ocorreu um erro ao atualizar o formato de hora',
    'en-US': 'There was an error updating the time format',
    'es-419': 'Se produjo un error al cambiar el formato del tiempo',
    'ko-KR': '시간 형식을 업데이트하는 중에 오류가 발생했습니다.',
    'id-ID': 'Terjadi kesalahan saat memperbarui format waktu'
  },
  timezone: {
    'pt-BR': 'Ocorreu um erro ao atualizar o fuso horário',
    'en-US': 'There was an error updating the timezone',
    'es-419': 'Se produjo un error al cambiar la zona horaria',
    'ko-KR': '시간대를 업데이트하는 중에 오류가 발생했습니다.',
    'id-ID': 'Terjadi kesalahan saat memperbarui zona waktu'
  }
};

export const patchUserMetadata = async (
  data: PatchUserMetadataTypes,
  language: string
): Promise<boolean> => {
  const userPreferences = Object.keys(data);
  const log = logService();

  try {
    const req = await Api.patch({
      url: SERVICES_URLS.USER.METADATA,
      body: data
    });

    if (req.hasError) {
      log.error(req.error?.message);
      throw new Error();
    }

    userPreferences.forEach((key) => {
      toastService().notify({
        type: TypeToast.SUCCESS,
        message: successMessages[key][data?.preferredLanguage ?? language]
      });
    });

    return true;
  } catch (e) {
    userPreferences.forEach((key) => {
      log.error(errorMessages[key][data?.preferredLanguage ?? language]);
      toastService().notify({
        type: TypeToast.ERROR,
        message: errorMessages[key][data?.preferredLanguage ?? language]
      });
    });

    return false;
  }
};
